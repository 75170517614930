import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { SectionHeader, SectionLayout } from '../../common'

import * as aboutStyles from './about.module.scss'
import PreviewCompatibleImage from '../../PreviewCompatibleImage'

const About = forwardRef(({ id, profileImage }, ref) => (
  <SectionLayout id={id} ref={ref} className={aboutStyles.section}>
    <div className={aboutStyles.about}>
      <div className={aboutStyles.column}>
        <div className={aboutStyles.me}>
          <SectionHeader>Über mich</SectionHeader>
          <div className={aboutStyles.decoration} />
        </div>
        <p>
          Meine Name ist Jana Cseh. Ich bin Gyrotonic® Trainerin und unterrichte
          auch klassisches Ballett.
          <br />
          Seit Kindheit war Bewegung ein wichtiger Teil meines Lebens.
          Angefangen habe ich mit Turnen und bin später zum Ballett gewechselt.
          Am Staats Tanz Konservatorium in Prag habe ich meine klassische
          Ballett Ausbildung bekommen.
          <br />
          Es folgten Engagements im Landestheater Ceske Budejovice, Stages in
          Frankreich und Engagements in der Staatsoper Prag.
          <br />
          In Wien habe ich dann Gyrotonic® entdeckt und mich in dieses
          einzigartige Bewegungskonzept verliebt. Im Jahr 2008 habe ich mein
          Ausbildung zur Gyrotonic® Trainer absolviert . Die Trainer Ausbildung
          umfasste Level 1 Level 2, Gyrotonic® spezielles Anwendung für
          Skoliose,Gyrotonic® für Tänzer, Jumping Stretching Board, Archway, Leg
          Extension und Gyrotoner.
          <br />
          Ich freue mich, wenn ich meine Erfahrungen weiter geben kann und
          Menschen bei Rückenschmerzen, Nacken - und Gelenkschmerzen oder
          einfach nur sich im eigenen Körper wohlzufühlen helfen kann.
        </p>
      </div>
      <div className={aboutStyles.divider} />
      <div className={aboutStyles.column}>
        <SectionHeader>Über GYROTONIC®</SectionHeader>
        <p>
          Gyrotonic® ist ein dreidimensionales Bewegungs-Therapie-System. Die
          Wirbelsäule wird als zentraler Träger gesehen. Die dafür spezial
          entwickelten Geräte unterstützen die Bewegungen. Durch leichten
          Widerstand werden die Muskel gestärkt und gleichzeitig gedehnt, die
          Gelenke werden entlastet und mobilisiert und die Koordination
          verbessert. Flüssige kreis- und spiralförmige Bewegungen sind von
          synchronisierter Atmung begleitet, was zur körperlichen und mentalen
          Entspannung führt.
        </p>
        {profileImage && (
          <PreviewCompatibleImage
            className={aboutStyles.profilePhoto}
            imageInfo={profileImage}
          />
        )}
      </div>
    </div>
  </SectionLayout>
))

About.propTypes = {
  id: PropTypes.string.isRequired,
}

export default About
