import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import ArrowDown from '../../img/assets/icons/arrow-circle-down.svg'
import ArrowUp from '../../img/assets/icons/arrow-circle-up.svg'
import Call from '../../img/assets/icons/call.svg'
import Gallery from '../../img/assets/icons/gallery.svg'
import HashTag from '../../img/assets/icons/hashtag.svg'
import Instagram from '../../img/assets/icons/instagram.svg'
import Location from '../../img/assets/icons/location.svg'
import MessageText from '../../img/assets/icons/message-text-1.svg'
import Money from '../../img/assets/icons/money-4.svg'
import ProfileCircle from '../../img/assets/icons/profile-circle.svg'
import StrongBox from '../../img/assets/icons/strongbox.svg'
import Timer from '../../img/assets/icons/timer.svg'
import Weight from '../../img/assets/icons/weight.svg'
import Heart from '../../img/assets/icons/heart.svg'
import Forward from '../../img/assets/icons/forward.svg'
import House from '../../img/assets/icons/house.svg'
import ArrowDown2 from '../../img/assets/icons/arrow-down-1.svg'
import Play from '../../img/assets/icons/play.svg'

const iconTypes = {
  arrowDown: 'arrowDown',
  arrowUp: 'arrowUp',
  call: 'call',
  gallery: 'gallery',
  instagram: 'instagram',
  location: 'location',
  messageText: 'messageText',
  money: 'money',
  profileCircle: 'profileCircle',
  strongBox: 'strongBox',
  timer: 'timer',
  weight: 'weight',
  heart: 'heart',
  forward: 'forward',
  house: 'house',
  arrowDown2: 'arrowDown2',
  play: 'play',
}

const getComponent = (type) => {
  switch (type) {
    case iconTypes.arrowDown:
      return ArrowDown
    case iconTypes.arrowUp:
      return ArrowUp
    case iconTypes.call:
      return Call
    case iconTypes.gallery:
      return Gallery
    case iconTypes.instagram:
      return Instagram
    case iconTypes.location:
      return Location
    case iconTypes.messageText:
      return MessageText
    case iconTypes.money:
      return Money
    case iconTypes.profileCircle:
      return ProfileCircle
    case iconTypes.strongBox:
      return StrongBox
    case iconTypes.timer:
      return Timer
    case iconTypes.weight:
      return Weight
    case iconTypes.heart:
      return Heart
    case iconTypes.forward:
      return Forward
    case iconTypes.house:
      return House
    case iconTypes.arrowDown2:
      return ArrowDown2
    case iconTypes.play:
      return Play
    default:
      return HashTag
  }
}

const Icon = ({ type, className }) => {
  const Component = useMemo(() => getComponent(type), [type])
  return <Component className={className} />
}

Icon.types = Object.freeze(iconTypes)

Icon.propTypes = {
  type: PropTypes.oneOf(Object.values(iconTypes)).isRequired,
  className: PropTypes.string,
}

export default Icon
