import React, { useState } from 'react'
import { withPrefix } from 'gatsby'

import * as openingStyles from './opening.module.scss'

import { Icon } from '../../common'

const Interview = () => {
  const [isVideoVisible, setIsVideoVisible] = useState(false)

  return (
    <div className={openingStyles.interview}>
      <button onClick={() => setIsVideoVisible(true)}>
        <Icon type={Icon.types.play} />
      </button>
      <img
        src={`${withPrefix('/')}img/interview_preview.png`}
        alt="gyrotonic_interview"
      />
      {isVideoVisible && (
        <div className={openingStyles.interviewVideo}>
          <div
            className={openingStyles.videoWrapper}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (e.currentTarget === e.target) {
                setIsVideoVisible(false)
              }
            }}
          >
            <button
              className={openingStyles.close}
              onClick={() => setIsVideoVisible(false)}
            >
              X
            </button>
            <video
              controls
              title="Interview"
              autoPlay={true}
              style={{ width: '100%' }}
            >
              <source
                src={`${withPrefix('/')}img/gyrotonic_interview.mp4`}
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      )}
    </div>
  )
}

export default Interview
