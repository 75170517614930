import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import LogoFull from '../../../img/assets/logo/logo-full.svg'

import { Icon, Button } from '../../common'

import * as openingStyles from './opening.module.scss'
import { SECTION_ABOUT } from '../constants'
import { scrollAndFillContactForm, scrollToSection } from '../utils'
import Offer from './Offer'
import Interview from './Interview'

const Opening = forwardRef(({ title, description, id, offer }, ref) => (
  <section className={openingStyles.container} ref={ref} id={id}>
    <div className={openingStyles.headerWrapper}>
      <header>
        <LogoFull />
        {/*TODO :: add facts after translation is ready*/}
        {/*<Facts/>*/}
        <Button text="Kontakt" onClick={() => scrollAndFillContactForm('')} />
      </header>
      {offer?.visible && (
        <Offer
          text={offer?.text}
          backgroundColor={offer?.backgroundColor}
          color={offer?.color}
        />
      )}
    </div>
    <div className={openingStyles.title}>
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
    <div className={openingStyles.more}>
      <button onClick={() => scrollToSection(SECTION_ABOUT)}>
        <Icon type={Icon.types.arrowDown} />
        <p>Finde mehr heraus</p>
      </button>
    </div>
    <Interview />
  </section>
))

Opening.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  offer: PropTypes.shape({
    visible: PropTypes.bool,
    text: PropTypes.string,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
  }),
}

export default Opening
