import React from 'react'
import PropTypes from 'prop-types'

import * as sectionHeaderStyles from './sectionHeader.module.scss'

const SectionHeader = ({ children, subheader }) => (
  <div className={sectionHeaderStyles.container}>
    <h2 className={sectionHeaderStyles.header}>{children}</h2>
    {subheader && (
      <h4 className={sectionHeaderStyles.subheader}>{subheader}</h4>
    )}
  </div>
)

SectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  subheader: PropTypes.string,
}

export default SectionHeader
