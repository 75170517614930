import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { SectionLayout } from '../../common'

import Form from './Form'
import Info from './Info'

import * as contactStyles from './contact.module.scss'

const Contact = forwardRef(({ id, contact }, ref) => (
  <SectionLayout
    ref={ref}
    id={id}
    className={contactStyles.section}
    header="Kontaktieren sie mich"
    subheader="und ich schreibe ihnen zurück"
  >
    <div className={contactStyles.contact}>
      <Form />
      <Info contact={contact} />
    </div>
  </SectionLayout>
))

Contact.propTypes = {
  id: PropTypes.string.isRequired,
  contact: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string,
    instagram: PropTypes.string,
  }),
}

export default Contact
