import React, { useState } from 'react'
import { Scrollchor, swing } from 'react-scrollchor'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Logo from '../../img/assets/logo/logo.svg'

import * as navigationStyles from './navigation.module.scss'
import Icon from './Icon'
import {
  SECTION_ABOUT,
  SECTION_ACTUALITIES,
  SECTION_CONTACT,
  SECTION_GALLERY,
  SECTION_OPTIONS,
  SECTION_PRICES,
} from '../sections'

const defaultAnimate = {
  offset: 0,
  duration: 500,
  easing: swing,
}

const NavigationButton = ({
  children,
  isActive,
  navigateToSection,
  tooltip,
}) => (
  <Scrollchor
    to={navigateToSection}
    className={classnames(navigationStyles.button, {
      [navigationStyles.active]: isActive,
    })}
    animate={defaultAnimate}
  >
    <div className={navigationStyles.tooltip}>{tooltip}</div>
    {children}
  </Scrollchor>
)

const OpenNavigationButton = ({ children, onClick, className }) => (
  <button className={className} onClick={onClick}>
    {children}
  </button>
)

const Navigation = ({ activeSection }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <OpenNavigationButton
        className={classnames(
          navigationStyles.openButton,
          navigationStyles[activeSection],
          { [navigationStyles.open]: isOpen }
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon type={Icon.types.arrowDown2} />
      </OpenNavigationButton>
      <nav
        className={classnames(navigationStyles[activeSection], {
          [navigationStyles.open]: isOpen,
        })}
      >
        <div className={navigationStyles.logo}>
          <Logo />
        </div>
        <div className={navigationStyles.wrapper}>
          <NavigationButton
            navigateToSection={SECTION_ACTUALITIES}
            isActive={activeSection === SECTION_ACTUALITIES}
            tooltip="Aktualität"
          >
            <Icon type={Icon.types.timer} />
          </NavigationButton>
          <NavigationButton
            tooltip="Über mich"
            navigateToSection={SECTION_ABOUT}
            isActive={activeSection === SECTION_ABOUT}
          >
            <Icon type={Icon.types.profileCircle} />
          </NavigationButton>
          <NavigationButton
            tooltip="Für Wen ist Gyrotonic geeignet"
            navigateToSection={SECTION_OPTIONS}
            isActive={activeSection === SECTION_OPTIONS}
          >
            <Icon type={Icon.types.strongBox} />
          </NavigationButton>
          <NavigationButton
            tooltip="Preise"
            navigateToSection={SECTION_PRICES}
            isActive={activeSection === SECTION_PRICES}
          >
            <Icon type={Icon.types.money} />
          </NavigationButton>
          <NavigationButton
            tooltip="Galerie"
            navigateToSection={SECTION_GALLERY}
            isActive={activeSection === SECTION_GALLERY}
          >
            <Icon type={Icon.types.gallery} />
          </NavigationButton>
          <NavigationButton
            tooltip="Kontakt"
            navigateToSection={SECTION_CONTACT}
            isActive={activeSection === SECTION_CONTACT}
          >
            <Icon type={Icon.types.call} />
          </NavigationButton>
        </div>
      </nav>
    </>
  )
}

Navigation.propTypes = {
  activeSection: PropTypes.string.isRequired,
}

export default Navigation
