import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import useScrollSpy from 'react-use-scrollspy'

import {
  Opening,
  Actualities,
  About,
  Options,
  Prices,
  Gallery,
  Contact,
  Footer,
  SECTION_OPENING,
  SECTION_ACTUALITIES,
  SECTION_ABOUT,
  SECTION_OPTIONS,
  SECTION_PRICES,
  SECTION_GALLERY,
  SECTION_CONTACT,
} from '../sections'
import { Navigation } from '../common'

const IndexPageTemplate = ({
  opening,
  actualities,
  gallery,
  contact,
  profileImage,
  prices,
}) => {
  const sectionRefs = {
    [SECTION_OPENING]: useRef(null),
    [SECTION_ACTUALITIES]: useRef(null),
    [SECTION_ABOUT]: useRef(null),
    [SECTION_OPTIONS]: useRef(null),
    [SECTION_PRICES]: useRef(null),
    [SECTION_GALLERY]: useRef(null),
    [SECTION_CONTACT]: useRef(null),
  }

  const activeSectionIndex = useScrollSpy({
    sectionElementRefs: Object.values(sectionRefs),
    offsetPx: -120,
  })

  return (
    <>
      <Navigation
        activeSection={Object.keys(sectionRefs)[activeSectionIndex]}
      />
      <Opening
        id={SECTION_OPENING}
        ref={sectionRefs[SECTION_OPENING]}
        title={opening.title}
        description={opening.description}
        offer={opening.offer}
      />
      <Actualities
        actualities={actualities}
        id={SECTION_ACTUALITIES}
        ref={sectionRefs[SECTION_ACTUALITIES]}
      />
      <About
        id={SECTION_ABOUT}
        ref={sectionRefs[SECTION_ABOUT]}
        profileImage={profileImage}
      />
      <Options id={SECTION_OPTIONS} ref={sectionRefs[SECTION_OPTIONS]} />
      <Prices
        id={SECTION_PRICES}
        ref={sectionRefs[SECTION_PRICES]}
        prices={prices}
      />
      <Gallery
        gallery={gallery}
        id={SECTION_GALLERY}
        ref={sectionRefs[SECTION_GALLERY]}
      />
      <Contact
        id={SECTION_CONTACT}
        ref={sectionRefs[SECTION_CONTACT]}
        contact={contact}
      />
      <Footer />
    </>
  )
}

IndexPageTemplate.propTypes = {
  opening: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    offer: PropTypes.shape({
      visible: PropTypes.bool,
      text: PropTypes.string,
      backgroundColor: PropTypes.string,
      color: PropTypes.string,
    }),
  }),
  actualities: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  prices: PropTypes.object,
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      childImageSharp: PropTypes.object,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
    })
  ),
  contact: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string,
    instagram: PropTypes.string,
  }),
}

export default IndexPageTemplate
