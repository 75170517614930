import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, IndexPageTemplate } from '../components'

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        profileImage={data.profileImage}
        opening={frontmatter.opening}
        actualities={frontmatter.actualities}
        gallery={frontmatter.gallery}
        contact={frontmatter.contact}
        prices={frontmatter.prices}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    profileImage: file(relativePath: { eq: "Gyrotonic_profile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        opening {
          title
          description
          offer {
            text
            backgroundColor
            color
            visible
          }
        }
        actualities {
          date
          text
        }
        prices {
          first {
            label
            price
            specialPrice
            description
          }
          second {
            label
            price
            specialPrice
            description
          }
          third {
            label
            price
            specialPrice
            description
          }
          fourth {
            label
            price
            specialPrice
            description
          }
          fifth {
            label
            price
            specialPrice
            description
          }
        }
        gallery {
          image {
            childImageSharp {
              fluid(maxWidth: 1080, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
        contact {
          email
          phone
          address
          instagram
        }
      }
    }
  }
`
