// extracted by mini-css-extract-plugin
export var desc = "prices-module--desc--qAPE5";
export var group = "prices-module--group--kf0b9";
export var info = "prices-module--info--kTvvf";
export var large = "prices-module--large--XOK-L";
export var medium = "prices-module--medium--zOYTn";
export var price = "prices-module--price--XD160";
export var prices = "prices-module--prices--DOMHa";
export var section = "prices-module--section--EGd4X";
export var specialPrice = "prices-module--specialPrice--l45Nc";
export var text = "prices-module--text--N7ARw";
export var withoutCircle = "prices-module--withoutCircle--sIli+";