import React from 'react'
import PropTypes from 'prop-types'

import * as buttonStyles from './button.module.scss'
import classnames from 'classnames'

const Button = ({ text, onClick, className, type }) => (
  <button
    className={classnames(buttonStyles.main, className)}
    onClick={onClick || (() => {})}
    type={type}
  >
    {text}
  </button>
)

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
}

export default Button
