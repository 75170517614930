import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from '../../common'

import * as infoStyles from './info.module.scss'

const Info = ({ contact }) => (
  <div className={infoStyles.info}>
    <div className={infoStyles.infoWrapper}>
      <Icon type={Icon.types.messageText} />
      <a href={`mailto:${contact.email}`}>{contact.email}</a>
    </div>
    <div className={infoStyles.infoWrapper}>
      <Icon type={Icon.types.house} />
      <p>{contact.address}</p>
    </div>
    <div className={infoStyles.infoWrapper}>
      <Icon type={Icon.types.call} />
      <p>{contact.phone}</p>
    </div>
    <div className={infoStyles.infoWrapper}>
      <Icon type={Icon.types.instagram} />
      <a href={`https://www.instagram.com/${contact.instagram}`}>
        {contact.instagram}
      </a>
    </div>
  </div>
)

Info.propTypes = {
  contact: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string,
    instagram: PropTypes.string,
  }),
}

export default Info
