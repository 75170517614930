import { CONTACT_FORM_TEXTAREA_ID, SECTION_CONTACT } from './constants'

export const scrollToSection = (sectionId) => {
  const aboutSection = document.getElementById(sectionId)
  aboutSection.scrollIntoView({ behavior: 'smooth' })
}

const fillContactFormTextarea = (text) => {
  const contactFormTextarea = document.getElementById(CONTACT_FORM_TEXTAREA_ID)
  console.log(contactFormTextarea)
  contactFormTextarea.value = text
}

export const scrollAndFillContactForm = (text) => {
  console.log('scrollAndFill')
  scrollToSection(SECTION_CONTACT)
  fillContactFormTextarea(text)
}
