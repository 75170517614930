import React from 'react'
import * as galleryStyles from './gallery.module.scss'

export const Video = ({ videoSrcURL, videoTitle }) => (
  <div className={galleryStyles.videoWrapper}>
    <video controls className={galleryStyles.video} title={videoTitle}>
      <source src={videoSrcURL} type="video/mp4" />
    </video>
  </div>
)
