import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { Button, SectionLayout } from '../../common'

import * as optionsStyles from './options.module.scss'
import Icon from '../../common/Icon'
import Option from './Option'
import { scrollAndFillContactForm } from '../utils'

const optionList = [
  {
    key: 1,
    icon: <Icon type={Icon.types.heart} className={optionsStyles.senior} />,
    name: 'Für Senioren',
    text:
      'Gyrotonic ist ideal für Menschen mit Gelenkproblemen, Rückenschmerzen, Rheuma und' +
      ' Arthrose.Sie bewegen sich sanft und ohne großen Kraftaufwand, das Verletzungsrisiko wird' +
      ' dadurch minimiert.' +
      ' Gyrotonic hilft auch Haltungsungleichgewicht zu korrigieren.' +
      ' Das Einzeltraining wird auf individuelle Bedürfnisse angepasst.',
    button: (
      <Button
        text="Ich bin interessiert"
        onClick={() => scrollAndFillContactForm('Für Senioren')}
      />
    ),
  },
  {
    key: 2,
    icon: <Icon type={Icon.types.forward} className={optionsStyles.junior} />,
    name: 'Für Tänzer',
    text:
      'Gyrotonic ist ein System, das von Tänzern für Tänzer entwickelt wurde.' +
      ' Gyrotonic kräftig die tiefe Muskulatur und verbessert die allgemeine Flexibilität.' +
      ' Beim Training werden Gelenke entlastet und ihre Beweglichkeit gesteigert.' +
      ' Durch die Arbeit an der Haltung wird auch das Körperzentrum gestärkt,' +
      ' wodurch eine sehr gute Balance erzielt wird.' +
      ' Dank Gyrotonic erhält man ein tiefes Verständnis für den eigenen Körper,' +
      ' Muskeln, Sehnen und Faszienverbindungen.',
    button: (
      <Button
        text="Ich bin interessiert"
        onClick={() => scrollAndFillContactForm('Für Tänzer')}
      />
    ),
  },
  {
    key: 3,
    icon: <Icon type={Icon.types.weight} className={optionsStyles.medior} />,
    name: 'Für Alle',
    text:
      'die eine neue Sportart entdeckten wollen, die vom langen Sitzen vor dem Computer Probleme' +
      ' im Nacken,Schultern und Rücken haben, die Harmonie im Körper herstellen wollen,' +
      ' oder die einfach nur die eigene Fitness verbessern und dabei Spass haben wollen.',
    button: (
      <Button
        text="Ich bin interessiert"
        onClick={() => scrollAndFillContactForm('Für Alle')}
      />
    ),
  },
]

const Options = forwardRef(({ id }, ref) => (
  <SectionLayout
    ref={ref}
    id={id}
    className={optionsStyles.section}
    header="Für Wen ist Gyrotonic geeignet"
  >
    <div className={optionsStyles.options}>
      {optionList.map((opt) => (
        <Option key={opt.key} {...opt} />
      ))}
    </div>
  </SectionLayout>
))

Options.propTypes = {
  id: PropTypes.string.isRequired,
}

export default Options
