import React, { forwardRef, useState, useCallback, useEffect } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import PropTypes from 'prop-types'

import { SectionLayout } from '../../common'

import * as galleryStyles from './gallery.module.scss'
import PreviewCompatibleImage from '../../PreviewCompatibleImage'
import { NextButton, PrevButton } from './GalleryButtons'
import { Video } from './Video'
import { withPrefix } from 'gatsby'

const Gallery = forwardRef(({ id, gallery }, ref) => {
  const [emblaRef, embla] = useEmblaCarousel({
    loop: true,
    skipSnaps: false,
  })

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])
  const onSelect = useCallback(() => {
    if (!embla) {
      return
    }
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla])

  useEffect(() => {
    if (!embla) {
      return
    }
    embla.on('select', onSelect)
    onSelect()
  }, [embla, onSelect])

  return (
    <SectionLayout
      id={id}
      ref={ref}
      className={galleryStyles.section}
      header="Galerie"
      subheader="Fotos, Video - Sandra Breit"
    >
      <div className={galleryStyles.embla}>
        <div className={galleryStyles.viewport} ref={emblaRef}>
          <div className={galleryStyles.container}>
            <>
              <div key="video" className={galleryStyles.slide}>
                <div className={galleryStyles.slideInner}>
                  <Video
                    videoSrcURL={`${withPrefix('/')}img/welcome_video.mp4`}
                    videoTitle="Welcome"
                  />
                </div>
              </div>
              {gallery.map((imageInfo) => (
                <div key={imageInfo.alt} className={galleryStyles.slide}>
                  <div className={galleryStyles.slideInner}>
                    <PreviewCompatibleImage imageInfo={imageInfo} />
                  </div>
                </div>
              ))}
            </>
          </div>
        </div>
        <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
      </div>
    </SectionLayout>
  )
})

Gallery.propTypes = {
  id: PropTypes.string.isRequired,
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      childImageSharp: PropTypes.object,
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
    })
  ),
}

export default Gallery
