import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import SectionHeader from './SectionHeader'

import * as sectionStyles from './sectionLayout.module.scss'

const SectionLayout = forwardRef(
  ({ children, header, subheader, className, id }, ref) => (
    <section
      id={id}
      ref={ref}
      className={classnames(sectionStyles.section, className)}
    >
      <div className={sectionStyles.wrapper}>
        {header && (
          <SectionHeader subheader={subheader}>{header}</SectionHeader>
        )}
        {children}
      </div>
    </section>
  )
)

SectionLayout.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  className: PropTypes.string,
}

export default SectionLayout
