import React from 'react'
import PropTypes from 'prop-types'

import * as optionStyles from './option.module.scss'

const Options = ({ icon, name, text, button }) => (
  <div className={optionStyles.container}>
    <div className={optionStyles.icon}>{icon}</div>
    <h3>{name}</h3>
    <p>{text}</p>
    {button}
  </div>
)

Options.propTypes = {
  icon: PropTypes.node.isRequired,
  button: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default Options
