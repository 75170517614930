// extracted by mini-css-extract-plugin
export var about = "navigation-module--about--T5t6x";
export var active = "navigation-module--active--g8Lks";
export var actualities = "navigation-module--actualities--+339a";
export var button = "navigation-module--button--EHXAx";
export var contact = "navigation-module--contact--8JNvN";
export var gallery = "navigation-module--gallery--gslVV";
export var logo = "navigation-module--logo--G7iXI";
export var open = "navigation-module--open--XeO2m";
export var openButton = "navigation-module--openButton--OfMTR";
export var options = "navigation-module--options--ZD3Bs";
export var prices = "navigation-module--prices--53uuP";
export var tooltip = "navigation-module--tooltip--jDuRr";
export var wrapper = "navigation-module--wrapper--PYNSC";