// extracted by mini-css-extract-plugin
export var btn = "gallery-module--btn--UviGP";
export var container = "gallery-module--container--pshLo";
export var embla = "gallery-module--embla--EO28U";
export var next = "gallery-module--next--0uAxn";
export var prev = "gallery-module--prev--q5Qa2";
export var section = "gallery-module--section--KAmcs";
export var slide = "gallery-module--slide--CGUBv";
export var slideInner = "gallery-module--slide-inner--0V1P9";
export var video = "gallery-module--video--dcBwt";
export var videoWrapper = "gallery-module--videoWrapper--hz5dy";
export var viewport = "gallery-module--viewport--TQKyi";