import React, { useMemo, Fragment, forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { SectionLayout } from '../../common'

import * as actualitiesStyles from './actualities.module.scss'

const orderActualities = (actualities) =>
  actualities
    .map(({ date, text }) => ({ text, date: new Date(date) }))
    .sort((objA, objB) => Number(objB.date) - Number(objA.date))

const Actualities = forwardRef(({ actualities, id }, ref) => {
  const sortedActualities = useMemo(
    () => orderActualities(actualities),
    [actualities]
  )

  // TODO :: Čím víc času uteče mezi aktualitama tím větší bude mezera

  return (
    <SectionLayout
      id={id}
      ref={ref}
      className={actualitiesStyles.section}
      header="Aktualität"
    >
      <div className={actualitiesStyles.actualities}>
        {sortedActualities && sortedActualities.length > 0 ? (
          sortedActualities.map(({ date, text }, index) => (
            <Fragment key={index}>
              <div>{date.toLocaleDateString()}</div>
              <div className={actualitiesStyles.separatorWrapper}>
                <div
                  className={classnames(actualitiesStyles.separator, {
                    [actualitiesStyles.first]: index === 0,
                  })}
                />
                <div
                  className={classnames(actualitiesStyles.connector, {
                    [actualitiesStyles.hidden]:
                      index === sortedActualities.length - 1,
                  })}
                />
              </div>
              <span className={actualitiesStyles.text}>{text}</span>
            </Fragment>
          ))
        ) : (
          <p>Es gibt keine Neuigkeiten</p>
        )}
      </div>
    </SectionLayout>
  )
})

Actualities.propTypes = {
  id: PropTypes.string.isRequired,
  actualities: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
}

export default Actualities
