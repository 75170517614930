import React from 'react'
import { useForm } from 'react-hook-form'

import { Button } from '../../common'

import * as formStyles from './form.module.scss'
import { CONTACT_FORM_TEXTAREA_ID } from '../constants'

const Form = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()

  const onSubmit = (data) => {
    fetch('/.netlify/functions/email', {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(function (response) {
        if (response.status === 200) {
          alert('Email wurde erfolgreich Versendet')
        } else {
          alert(
            'E-Mail nicht gesendet. Bitte nutzen Sie zur Kontaktaufnahme die Telefonnummer oder E-Mail'
          )
        }
      })
      .catch(function () {
        alert(
          'E-Mail nicht gesendet. Bitte nutzen Sie zur Kontaktaufnahme die Telefonnummer oder E-Mail'
        )
      })
  }

  return (
    <form className={formStyles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={formStyles.splitColumn}>
        <div>
          <label htmlFor="name">Name</label>
          <input id="name" {...register('name', { required: true })} />
          {errors.name && (
            <div className={formStyles.formError}>Name ist erforderlich</div>
          )}
        </div>
        <div>
          <label htmlFor="email">E-mail</label>
          <input
            id="email"
            type="email"
            {...register('email', { required: true })}
          />
          {errors.email && (
            <div className={formStyles.formError}>Email ist erforderlich</div>
          )}
        </div>
      </div>
      <div>
        <label htmlFor={CONTACT_FORM_TEXTAREA_ID}>Text</label>
        <textarea
          id={CONTACT_FORM_TEXTAREA_ID}
          rows="6"
          {...register('text', { required: true })}
        />
        {errors.text && (
          <div className={formStyles.formError}>Text ist erforderlich</div>
        )}
      </div>
      <div>Wir verwenden Ihre Informationen nur, um Sie zu kontaktieren.</div>
      <Button type="submit" text="Senden" disabled={isSubmitting} />
    </form>
  )
}

export default Form
