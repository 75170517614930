import React from 'react'
import PropTypes from 'prop-types'

import * as openingStyles from './opening.module.scss'

const Offer = ({ backgroundColor, text, color }) => (
  <div style={{ backgroundColor, color }} className={openingStyles.offer}>
    {text}
  </div>
)

Offer.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default Offer
