import React, { forwardRef } from 'react'
import classnames from 'classnames'

import { SectionLayout } from '../../common'

import * as pricesStyles from './prices.module.scss'
import PropTypes from 'prop-types'

const PriceContent = ({ label, price, description, specialPrice }) => (
  <>
    <div>{label}</div>
    {specialPrice ? (
      <p
        className={classnames(pricesStyles.text, {
          [pricesStyles.specialPrice]: !!specialPrice,
        })}
      >
        {specialPrice && <span>{`${specialPrice}€`}</span>}
        {`${price}€`}
      </p>
    ) : (
      <p className={pricesStyles.text}>{`${price}€`}</p>
    )}
    {description ? (
      <p className={pricesStyles.desc}>{description}</p>
    ) : (
      <p className={pricesStyles.desc}>&nbsp;</p>
    )}
  </>
)

const Prices = forwardRef(({ id, prices }, ref) => (
  <SectionLayout
    ref={ref}
    id={id}
    className={pricesStyles.section}
    header="Preise"
  >
    <div className={pricesStyles.prices}>
      <div
        className={classnames(pricesStyles.price, pricesStyles.withoutCircle)}
      >
        <PriceContent {...prices.first} />
      </div>
      <div className={classnames(pricesStyles.price)}>
        <PriceContent {...prices.second} />
      </div>
      <div className={classnames(pricesStyles.price, pricesStyles.medium)}>
        <PriceContent {...prices.third} />
      </div>
      <div className={classnames(pricesStyles.price, pricesStyles.large)}>
        <PriceContent {...prices.fourth} />
      </div>
      <div className={classnames(pricesStyles.price, pricesStyles.group)}>
        <PriceContent {...prices.fifth} />
      </div>
    </div>
  </SectionLayout>
))

Prices.propTypes = {
  id: PropTypes.string.isRequired,
}

export default Prices
