import React from 'react'

import Logo from '../../../img/assets/logo/logo-solid-color.svg'

import * as footerStyles from './footer.module.scss'

const Footer = () => (
  <footer className={footerStyles.footer}>
    <div className={footerStyles.wrapper}>
      <div className={footerStyles.logo}>
        <Logo />
      </div>
      <div className={footerStyles.content}>
        <p>&copy; {new Date().getFullYear()} Movement poetry</p>
      </div>
    </div>
  </footer>
)

export default Footer
